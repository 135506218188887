import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Form from '../form/Form';
import AutoCompleteInput from '../form/AutoCompleteInput';
import TextInput from '../form/TextInput';
import DateInput from '../form/DateInput';
import SelectInput from '../form/SelectInput';
import SelectBooleanInput from '../form/SelectBooleanInput';
import useSchools from '../../hooks/useSchools';
import useStudentGenders from '../../hooks/useStudentGenders';
import useStates from '../../hooks/useStates';
import getValidationSchema, {
  stringValidation,
  dateValidation,
  idSelectionValidation,
  phoneNumberValidation,
  postcodeValidation,
  emailValidation,
  integerValidation,
  objectValidation,
  schoolYearValidation,
  ernNumberValidation,
  nesaNumberValidation,
} from '../form/ValidationSchema';
import { studentSaveRequested } from '../../redux/actions';

const StudentForm = ({ disabled, initialStudent }) => {
  const schema = {
    school: {
      validation: objectValidation,
      required: true,
    },
    school_year: {
      validation: schoolYearValidation,
      required: true,
    },
    nesa_number: {
      validation: nesaNumberValidation,
      required: false,
    },
    ern_number: {
      validation: ernNumberValidation,
      required: true,
    },
    first_name: {
      validation: stringValidation,
      required: true,
    },
    middle_name: {
      validation: stringValidation,
      required: false,
    },
    last_name: {
      validation: stringValidation,
      required: true,
    },
    date_of_birth: {
      validation: dateValidation,
      required: true,
    },
    gender_id: {
      validation: idSelectionValidation,
      required: true,
    },
    street_address: {
      validation: stringValidation,
      required: true,
    },
    suburb: {
      validation: stringValidation,
      required: true,
    },
    state_id: {
      validation: idSelectionValidation,
      required: true,
    },
    postcode: {
      validation: postcodeValidation,
      required: true,
    },
    phone: {
      validation: phoneNumberValidation,
      required: false,
    },
    mobile: {
      validation: phoneNumberValidation,
      required: false,
    },
    email: {
      validation: emailValidation,
      required: true,
    },
    aboriginal_or_torres: {
      validation: integerValidation,
      required: true,
    },
    parent_name: {
      validation: stringValidation,
      required: true,
    },
    parent_mobile: {
      validation: phoneNumberValidation,
      required: true,
    },
    parent_email: {
      validation: emailValidation,
      required: true,
    },
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, errors, register } = useForm({
    validationSchema: getValidationSchema(schema),
    defaultValues: {
      // Dates need to be cast to luxon Dates
      date_of_birth: initialStudent.date_of_birth
        ? DateTime.fromISO(initialStudent.date_of_birth)
        : null,
    },
  });

  const { schoolsUserCanView } = useSchools();

  const defaultSchool = initialStudent.id
    ? schoolsUserCanView.find((school) => {
        return school.id === initialStudent?.school?.id;
      })
    : (schoolsUserCanView?.length === 1 && schoolsUserCanView[0]) || null;

  const { genders } = useStudentGenders();
  const { states } = useStates();

  const saveErrors = useSelector((state) => state.students.saveErrors);

  const formatDatesOnSubmit = (data) => {
    data.date_of_birth = data.date_of_birth && data.date_of_birth.toISODate();
    return data;
  };

  const onSubmit = (data) => {
    data = formatDatesOnSubmit(data);
    dispatch(
      studentSaveRequested({
        id: initialStudent.id,
        data: {
          ...data,
          school: undefined,
          school_id: data?.school?.id ?? null,
        },
      })
    );
  };

  return (
    <Form
      submitText="submit"
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <AutoCompleteInput
            options={schoolsUserCanView}
            label="Select a school"
            key="school"
            name="school"
            control={control}
            defaultValue={defaultSchool}
            disabled={disabled || schoolsUserCanView.length === 1}
            hasError={Boolean(errors.school || saveErrors.school_id)}
            errorText={
              errors?.school?.message ?? saveErrors?.school_id?.message
            }
            requiredOnSubmit={schema.school.required}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="School year"
            name="school_year"
            defaultValue={initialStudent?.school_year ?? ''}
            control={control}
            hasError={Boolean(errors.school_year || saveErrors.school_year)}
            errorText={
              errors?.school_year?.message ?? saveErrors?.school_year?.message
            }
            requiredOnSubmit={schema.school_year.required}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Student NESA number"
            name="nesa_number"
            defaultValue={initialStudent?.nesa_number ?? ''}
            control={control}
            hasError={Boolean(errors.nesa_number || saveErrors.nesa_number)}
            errorText={
              errors?.nesa_number?.message ?? saveErrors?.nesa_number?.message
            }
            requiredOnSubmit={schema.nesa_number.required}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Student ERN number"
            name="ern_number"
            defaultValue={initialStudent?.ern_number ?? ''}
            control={control}
            hasError={Boolean(errors.ern_number || saveErrors.ern_number)}
            errorText={
              errors?.ern_number?.message ?? saveErrors?.ern_number?.message
            }
            requiredOnSubmit={schema.ern_number.required}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography color="secondary">
            Student name used must be student's official FULL name (matching
            birth certificate)
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="First Name"
            name="first_name"
            defaultValue={initialStudent?.name?.first ?? ''}
            control={control}
            hasError={Boolean(errors.first_name || saveErrors.first_name)}
            errorText={
              errors?.first_name?.message ?? saveErrors?.first_name?.message
            }
            requiredOnSubmit={schema.first_name.required}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Middle Name"
            name="middle_name"
            defaultValue={initialStudent?.name?.middle ?? ''}
            control={control}
            hasError={Boolean(errors.middle_name || saveErrors.middle_name)}
            errorText={
              errors?.middle_name?.message ?? saveErrors?.middle_name?.message
            }
            requiredOnSubmit={schema.middle_name.required}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Last Name"
            name="last_name"
            defaultValue={initialStudent?.name?.last ?? ''}
            control={control}
            hasError={Boolean(errors.last_name || saveErrors.last_name)}
            errorText={
              errors?.last_name?.message ?? saveErrors?.last_name?.message
            }
            requiredOnSubmit={schema.last_name.required}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <DateInput
            label="Date of Birth"
            name="date_of_birth"
            defaultValue={initialStudent.date_of_birth || ''}
            control={control}
            maxDate={new Date()}
            hasError={Boolean(errors.date_of_birth || saveErrors.date_of_birth)}
            errorText={
              errors?.date_of_birth?.message ??
              saveErrors?.date_of_birth?.message
            }
            requiredOnSubmit={schema.date_of_birth.required}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectInput
            label="Gender"
            name="gender_id"
            options={genders}
            control={control}
            defaultValue={initialStudent?.gender_id ?? ''}
            hasError={Boolean(errors.gender_id || saveErrors.gender_id)}
            errorText={
              errors?.gender_id?.message ?? saveErrors?.gender_id?.message
            }
            requiredOnSubmit={schema.gender_id.required}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectBooleanInput
            label="Aboriginal or Torres Strait Islander origin"
            name="aboriginal_or_torres"
            control={control}
            defaultValue={initialStudent?.aboriginal_or_torres ?? ''}
            hasError={Boolean(
              errors.aboriginal_or_torres || saveErrors.aboriginal_or_torres
            )}
            errorText={
              errors?.aboriginal_or_torres?.message ??
              saveErrors?.aboriginal_or_torres?.message
            }
            requiredOnSubmit={schema.aboriginal_or_torres.required}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Street Address"
            name="street_address"
            defaultValue={initialStudent?.street_address ?? ''}
            control={control}
            hasError={Boolean(
              errors.street_address || saveErrors.street_address
            )}
            errorText={
              errors?.street_address?.message ??
              saveErrors?.street_address?.message
            }
            requiredOnSubmit={schema.street_address.required}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Suburb"
            name="suburb"
            defaultValue={initialStudent?.suburb ?? ''}
            control={control}
            hasError={Boolean(errors.suburb || saveErrors.suburb)}
            errorText={errors?.suburb?.message ?? saveErrors?.suburb?.message}
            requiredOnSubmit={schema.suburb.required}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectInput
            label="State"
            name="state_id"
            options={states}
            control={control}
            defaultValue={initialStudent?.state_id ?? ''}
            hasError={Boolean(errors.state_id || saveErrors.state_id)}
            errorText={
              errors?.state_id?.message ?? saveErrors?.state_id?.message
            }
            requiredOnSubmit={schema.state_id.required}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Postcode"
            name="postcode"
            defaultValue={initialStudent?.postcode ?? ''}
            control={control}
            hasError={Boolean(errors.postcode || saveErrors.postcode)}
            errorText={
              errors?.postcode?.message ?? saveErrors?.postcode?.message
            }
            requiredOnSubmit={schema.postcode.required}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Phone"
            name="phone"
            defaultValue={initialStudent?.phone ?? ''}
            control={control}
            hasError={Boolean(errors.phone || saveErrors.phone)}
            errorText={errors?.phone?.message ?? saveErrors?.phone?.message}
            requiredOnSubmit={schema.phone.required}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextInput
            label="Mobile"
            name="mobile"
            defaultValue={initialStudent?.mobile ?? ''}
            control={control}
            hasError={Boolean(errors.mobile || saveErrors.mobile)}
            errorText={errors?.mobile?.message ?? saveErrors?.mobile?.message}
            requiredOnSubmit={schema.mobile.required}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Email"
            name="email"
            defaultValue={initialStudent?.email ?? ''}
            control={control}
            hasError={Boolean(errors.email || saveErrors.email)}
            errorText={errors?.email?.message ?? saveErrors?.email?.message}
            requiredOnSubmit={schema.email.required}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Parent Name"
            name="parent_name"
            defaultValue={initialStudent?.parent_name ?? ''}
            control={control}
            hasError={Boolean(errors.parent_name || saveErrors.parent_name)}
            errorText={
              errors?.parent_name?.message ?? saveErrors?.parent_name?.message
            }
            requiredOnSubmit={schema.parent_name.required}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Parent Mobile"
            name="parent_mobile"
            defaultValue={initialStudent?.parent_mobile ?? ''}
            control={control}
            hasError={Boolean(errors.parent_mobile || saveErrors.parent_mobile)}
            errorText={
              errors?.parent_mobile?.message ??
              saveErrors?.parent_mobile?.message
            }
            requiredOnSubmit={schema.parent_mobile.required}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TextInput
            label="Parent Email"
            name="parent_email"
            defaultValue={initialStudent?.parent_email ?? ''}
            control={control}
            hasError={Boolean(errors.parent_email || saveErrors.parent_email)}
            errorText={
              errors?.parent_email?.message ?? saveErrors?.parent_email?.message
            }
            requiredOnSubmit={schema.parent_email.required}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default StudentForm;
